import React from "react";
import {Button, Spinner} from "react-bootstrap";

export default function SubmitButtonWithSpinner({
                                                  variant = "primary",
                                                  submitFct,
                                                  formID = null,
                                                  children,
                                                  className = ""
                                                }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function submit(event) {
    event.preventDefault();
    if (isSubmitting) {
      // Prevent double submission
      return;
    }
    setIsSubmitting(true);
    submitFct().finally(() => {
      setIsSubmitting(false);
    });
  }

  return (
    <Button className={className} variant={variant} type={formID ? "submit" : "button"} form={formID} onClick={submit}>
      {isSubmitting ? <Spinner animation="border" size="sm"/> : children}
    </Button>
  );
}
