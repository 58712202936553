import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faFilePen} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import * as React from "react";

export default function Loading({message = null, error = false}) {
  if (message === null) {
    message = "Chargement en cours...";
  }

  let middleSection = (
    <Spinner animation="grow" variant={"info"}
             style={{width: "200px", height: "200px", animationDuration: '2s'}}/>
  )

  if (error) {
    middleSection = (
      <FontAwesomeIcon icon={faCircleExclamation} className="text-danger ms-2" size={"4x"}/>
    )
  }
  return (
    <div className="container-fluid pl-0">
      <div className="d-flex flex-row justify-content-center" style={{height: "100vh"}}>
        <div className="my-auto">
          <p className="text-xl bg-info text-white p-4" style={{borderRadius: "50px"}}>
            Portail rapport PCRS<FontAwesomeIcon icon={faFilePen} size={"xl"} className="ms-2"/>
          </p>
          <div className="text-center mt-4">
            {middleSection}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="text-center mt-4">
                <p>{message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
