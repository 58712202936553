import getPCRSAssessmentData, {initiatePCRSAssessmentBuilding} from "./paths/pcrs";

export async function PCRSDataLoader({params}) {
  let assessmentID = params.assessmentID;
  let data = await getPCRSAssessmentData(assessmentID);
  if (!data.success) {
    let errorCode = data.errorCode === undefined ? "500" : data.errorCode;
    throw new Response("axios error", {status: errorCode});
  }
  return data;
}

export async function PCRSBuildingDataLoader({params}) {
  let assessmentID = params.assessmentID;
  let data = await initiatePCRSAssessmentBuilding(assessmentID);
  if (!data.success) {
    let errorCode = data.errorCode === undefined ? "500" : data.errorCode;
    throw new Response("axios error", {status: errorCode});
  }
  return data;
}

