import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClockRotateLeft,
  faFloppyDisk,
  faPen,
  faRepeat,
  faTrash, faTrashCanArrowUp,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import {ModalContext} from "../contexts/ModalContext";
import SectionRowHistoryModal from "./SectionRowHistoryModal";
import React, {useRef} from "react";
import Editor from "react-simple-wysiwyg";

import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import ConfirmModal from "./utils/ConfirmModal";
import {DeletePCRSEntry, EditPCRSEntryText, HardDeletePCRSEntry, ResetPCRSEntry, RestorePCRSEntry} from "../paths/pcrs";
import {ToastMessageContext} from "../contexts/ToastMessageContext";
import SubmitButtonWithSpinner from "./utils/SubmitButtonWithSpinner";
import {Button} from "react-bootstrap";


export default function SectionRow({assessmentID, id, initialEditVal, text, setSectionDataFct, deleted}) {
  let {openModal} = React.useContext(ModalContext);
  let {toastMessage} = React.useContext(ToastMessageContext);
  const [editFlag, setEditFlag] = React.useState(initialEditVal)
  const [html, setHtml] = React.useState(text)
  let lastSavedText = useRef(text)

  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  }

  function openHistoryModal(rowID) {
    openModal(<SectionRowHistoryModal assessmentID={assessmentID} entryID={rowID}/>, "xl")
  }

  function openConfirmResetTextModal(rowID) {
    let title = "Réinitialiser le texte"
    let confirmMessage = "Êtes-vous sûr de vouloir réinitialiser le texte de cette phrase à sa valeur par défaut?"

    const resetEntry = async (params) => {
      let results = await ResetPCRSEntry(assessmentID, rowID);
      if (results.success) {
        setHtml(results.entryNewText);
      } else {
        toastMessage("Une erreur est survenue. Le texte original de la phrase n'a pas été restauré", "danger");
      }
      return results.success;
    }
    openModal(<ConfirmModal title={title} message={confirmMessage} handleAccept={resetEntry}/>, "md");
  }

  async function softDeleteEntry(rowID) {
    let result = await DeletePCRSEntry(assessmentID, rowID);
    if (result.success) {
      setSectionDataFct(result.sectionNewData);
    } else {
      toastMessage("Une erreur est survenue. La phrase n'a pas été supprimée", "danger");
    }
  }

  async function restoreEntry(rowID) {
    let results = await RestorePCRSEntry(assessmentID, rowID);
    if (results.success) {
      setSectionDataFct(results.sectionNewData);
    } else {
      toastMessage("Une erreur est survenue. La phrase n'a pas été restaurée", "danger");
    }
  }

  function openConfirmHardDeleteModal(rowID) {
    let title = "Supprimer définitivement la phrase"
    let confirmMessage = "Êtes-vous sûr de vouloir supprimer définitivement cette phrase?  Elle ne pourra pas être restaurée"

    const hardDeleteEntry = async (params) => {
      let results = await HardDeletePCRSEntry(assessmentID, rowID);
      if (results.success) {
        setSectionDataFct(results.sectionNewData);
      } else {
        toastMessage("Une erreur est survenue. La phrase n'a pas été définitivement supprimée", "danger");
      }
      return results.success;
    }

    openModal(<ConfirmModal title={title} message={confirmMessage} handleAccept={hardDeleteEntry}/>, "md");
  }

  async function saveEntryNewText(assessmentID, entryID) {
    let result = await EditPCRSEntryText(assessmentID, entryID, html);
    if (result.success) {
      setHtml(result.newText);
      setEditFlag(false);
    } else {
      toastMessage(result.error, "danger", "Une erreur est survenue. Le texte n'a pas été sauvegardé");
    }
  }

  const startTextEdit = () => {
    lastSavedText.current = html;
    setEditFlag(true);
  }

  const cancelTextEdit = () => {
    setHtml(lastSavedText.current);
    setEditFlag(false);
  }

  let textContent = <div dangerouslySetInnerHTML={{__html: html}}/>;
  if (editFlag) {
    textContent = <Editor value={html} onChange={(e) => setHtml(e.target.value)}/>
  }

  function getBtnOptions() {
    if (editFlag) {
      return (
        <>
          <SubmitButtonWithSpinner submitFct={() => saveEntryNewText(assessmentID, id)} variant={"link"}>
            <FontAwesomeIcon icon={faFloppyDisk}/>
          </SubmitButtonWithSpinner>
          <Button variant={"link"} onClick={cancelTextEdit}>
            <FontAwesomeIcon icon={faXmark}/>
          </Button>
        </>
      )
    }

    if (deleted) {
      return (
        <>
          <SubmitButtonWithSpinner submitFct={() => restoreEntry(id)} variant={"link"}>
            <FontAwesomeIcon icon={faTrashCanArrowUp}/>
          </SubmitButtonWithSpinner>
          <Button variant={"link"}>
            <FontAwesomeIcon icon={faTrash} className="text-danger"
                             onClick={() => openConfirmHardDeleteModal(id)}/>
          </Button>
        </>
      )
    }

    return (
      <>
        <Button variant={"link"} onClick={startTextEdit}>
          <FontAwesomeIcon icon={faPen}/>
        </Button>
        <SubmitButtonWithSpinner submitFct={() => softDeleteEntry(id)} variant={"link"} className="text-danger">
          <FontAwesomeIcon icon={faTrash}/>
        </SubmitButtonWithSpinner>
        <Button variant={"link"} onClick={() => openConfirmResetTextModal(id)}>
          <FontAwesomeIcon icon={faRepeat}/>
        </Button>
      </>
    )
  }

  let entryRowClasses = "row"
  if (deleted) {
    entryRowClasses += " soft-deleted-row"
  }

  return (
    <li ref={setNodeRef} style={style}>
      <div className={entryRowClasses}>
        <div className="col-1">
          <div className="d-flex h-100 justify-content-center align-middle">
            {!deleted &&
              <span {...attributes} {...listeners} className="my-auto">
              <FontAwesomeIcon icon={faBars} className="fa-2xl my-0"/>
            </span>
            }
          </div>
        </div>
        <div className="col-9 text-justify no-margin-paragraph">
          {textContent}
        </div>
        <div className="col-2">
          <div className="d-flex flex-row justify-content-center h-100 align-middle">
            {getBtnOptions()}
            <Button variant={"link"} onClick={() => openHistoryModal(id)}>
              <FontAwesomeIcon icon={faClockRotateLeft}/>
            </Button>
          </div>
        </div>
      </div>
    </li>
  )
}
