import axios from "axios";

const syspapAPI = axios.create({
  baseURL: process.env.REACT_APP_SYSPAP_BASE_URL + process.env.REACT_APP_SYSPAP_API_RELATIVE_URL,
});

syspapAPI.interceptors.request.use((config) => {
    const jwtToken = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN_NAME);
    if (jwtToken) {
      config.headers.Authorization = buildAuthHeader(jwtToken);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });


syspapAPI.interceptors.response.use(
  response => {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      console.log(originalRequest);
      originalRequest._retry = true
      const tokenRefreshed = await refreshAccessToken();
      if (tokenRefreshed) {
        let access_token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN_NAME);
        originalRequest.headers.Authorization = buildAuthHeader(access_token);
        return syspapAPI(originalRequest);
      }
    }
    return Promise.reject(error)
  }
);


async function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME);
    let noInterceptionAPI = axios.create({
      baseURL: syspapAPI.defaults.baseURL,
    });
    return noInterceptionAPI.post('/pcrs-assessment/token/refresh', {
      "refresh": refreshToken,
    })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem(process.env.REACT_APP_JWT_TOKEN_NAME, res.data.access);
          return resolve(true);
        }
        return resolve(false);
      })
      .catch(err => {
        return resolve(false);
      });
  });
}

function buildAuthHeader(jwtToken) {
  return `Bearer ${jwtToken}`;
}

export default syspapAPI;
