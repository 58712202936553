import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";
import EditPCRS from "./pages/EditPCRS";

import 'bootstrap/dist/css/bootstrap.css';
import {ModalProvider} from "./contexts/ModalContext";
import {PCRSBuildingDataLoader, PCRSDataLoader} from "./loaders.jsx";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ToastMessageProvider} from "./contexts/ToastMessageContext";
import HttpError from "./pages/errors/HttpError";
import axios from "axios";
import {buildSyspapApiURI, getErrorCodeFromAxiosError} from "./utils.js";
import RouterError from "./pages/errors/RouterError";
import * as Sentry from "@sentry/react";
import PCRSBuild from "./pages/PCRSBuild";
import Loading from "./pages/utils/Loading";

Sentry.init({
  dsn: "https://38f2d7fdf05b7ed2a2f473e153c8f57e@o52043.ingest.us.sentry.io/4508253216243712",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://pcrs.syspap.com", "https://pcrsdev2.syspap.com"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const axiosError = React.useRef(null);

  let skipLogin = React.useRef(false);


  React.useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    let urlToken = new URLSearchParams(window.location.search).get("token");
    if (urlToken === null) {
      skipLogin.current = true;
      setLoading(false);
      return;
    }

    let url = buildSyspapApiURI("/pcrs-assessment-login/" + urlToken);
    axios.get(url).then((response) => {
      let data = response.data;
      window.localStorage.setItem(process.env.REACT_APP_TITLE_STORAGE_NAME, data.user_title);
      let jwtToken = data.jwt_token;
      let refreshToken = data.refresh_token;
      window.localStorage.setItem(process.env.REACT_APP_JWT_TOKEN_NAME, jwtToken);
      window.localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_NAME, refreshToken);
      setIsLoggedIn(true);
      setLoading(false);
    }).catch((error) => {
      axiosError.current = getErrorCodeFromAxiosError(error);
      setLoading(false);
    });
  }, []);

  if (!skipLogin.current) {
    if (loading) {
      return <Loading message={"Connexion en cours..."}/>;
    } else if (!isLoggedIn) {
      return <HttpError errorCode={axiosError.current}/>;
    }
  }

  const router = createBrowserRouter([
    {
      path: "/pcrs/:assessmentID",
      loader: PCRSDataLoader,
      element: <EditPCRS/>,
      errorElement: <RouterError/>
    },
    {
      path: "/pcrs/:assessmentID/landing",
      loader: PCRSBuildingDataLoader,
      element: <PCRSBuild/>,
      errorElement: <RouterError/>
    },
    {
      path: "*",
      element: <RouterError/>,
    },
  ]);


  return (
    <React.StrictMode>
      <ToastMessageProvider>
        <ModalProvider>
          <div id="modal-container"></div>
          <RouterProvider router={router}/>
        </ModalProvider>
      </ToastMessageProvider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(<App/>);

