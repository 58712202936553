import SectionRow from "./SectionRow";
import React, {useCallback, useEffect, useState} from "react";

import {closestCorners, DndContext} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ModalContext} from "../contexts/ModalContext";
import AddNewSectionModal from "./AddNewSectionModal";
import {SavePCRSEntryOrderForSection} from "../paths/pcrs";
import {ToastMessageContext} from "../contexts/ToastMessageContext";
import {Button} from "react-bootstrap";

export default function Section({id, title, largeTitle, initialContent, spaceAfter, assessmentID}) {
  let {openModal} = React.useContext(ModalContext);
  let {toastMessage} = React.useContext(ToastMessageContext);

  const [entryOrder, setEntryOrder] = useState([]);
  const [sectionsData, setSectionsData] = useState(initialContent);

  const saveSectionOrder = useCallback(async (assessmentID, id, entryOrder) => {
    let result = await SavePCRSEntryOrderForSection(assessmentID, id, entryOrder);
    if (!result.success) {
      toastMessage("Une erreur s'est produite lors de la sauvegarde de l'ordre des phrases.  Veuillez rafraîchir la page", "danger");
    }
  }, [toastMessage]);

  useEffect(() => {
    const computeEntryOrder = () => sectionsData.map(entry => entry.id)

    let oldOrder = entryOrder;
    let newOrder = computeEntryOrder(sectionsData);
    if (oldOrder.length === newOrder.length && oldOrder.length !== 0) {
      // Addition/Deletion to the sectionData array don't change the order
      // No need to save the order if the section is empty
      saveSectionOrder(assessmentID, id, newOrder);
    }
    setEntryOrder(newOrder);
  }, [sectionsData]);

  function openAddSectionModal() {
    openModal(<AddNewSectionModal assessmentID={assessmentID} sectionCategoryID={id} sectionName={title}
                                  setSectionDataFct={setSectionsData}/>, "xl");
  }

  const getSectionPosition = (id) => {
    return sectionsData.findIndex(entry => entry.id === id)
  }

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id === over.id) return;
    setSectionsData((sectionsData) => {
      const oldIndex = getSectionPosition(active.id);
      const newIndex = getSectionPosition(over.id);
      return arrayMove(sectionsData, oldIndex, newIndex);
    });
  }

  const emptySectionMessage = (
    <div className="row mt-2">
      <div className="col-1"></div>
      <div className="col-11">
        <p>La section ne contient présentement aucune phrase</p>
      </div>
    </div>
  )

  const renderSectionData = (data) => {
    return (
      <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
        <ul className="p-0 list-spaced w-100 list-unstyled">
          <SortableContext items={data} strategy={verticalListSortingStrategy}>
            {data.map((entry) => {
              return <SectionRow key={"section-row-" + entry.id} assessmentID={assessmentID} id={entry.id}
                                 text={entry.text} initialEditVal={entry.edit} setSectionDataFct={setSectionsData}
                                 deleted={entry.deleted}/>
            })}
          </SortableContext>
        </ul>
      </DndContext>
    )
  }

  return (
    <>
      <div className={"individual-section my-4"}>
        {largeTitle && (
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-large-title">{largeTitle}</h1>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-row">
              <h2 className="section-title">{title}</h2>
              <Button variant={"success"} className="rounded-circle my-auto ms-2" onClick={() => openAddSectionModal()}>
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            </div>
          </div>
        </div>

        {sectionsData.length > 0 ? renderSectionData(sectionsData) : emptySectionMessage}
      </div>
      {spaceAfter && <div className="pb-4"></div>}
    </>
  )
}
