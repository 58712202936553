import React, {useEffect, useRef, useState} from "react";
import {getPCRSBuildStatus} from "../paths/pcrs";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import Loading from "./utils/Loading";

export default function PCRSBuild() {
  const {isBuilt, buildingInProgress} = useLoaderData();
  const {assessmentID} = useParams();
  const [pendingRequest, setPendingRequest] = useState(false);
  const requestCount = useRef(0);

  const [hasSuccessfullyBuilt, setHasSuccessfullyBuilt] = useState(isBuilt);
  const [buildingFailed, setBuildingFailed] = useState(false);
  let statusFunctionTimeout = useRef(null);

  const navigate = useNavigate();

  const updatePCRSBuildStatus = () => {
    if (pendingRequest) {
      return;
    }
    getPCRSBuildStatus(assessmentID).then((response) => {
      if (response.success) {
        if (response.isBuilt) {
          setHasSuccessfullyBuilt(true);
        }
        setPendingRequest(false);
      } else {
        setBuildingFailed(true);
      }
    });

    setPendingRequest(true);
    requestCount.current++;
  }

  function safeClearTimeout() {
    if (statusFunctionTimeout.current) {
      clearTimeout(statusFunctionTimeout.current);
    }
  }

  useEffect(() => {
    if (hasSuccessfullyBuilt) {
      safeClearTimeout();
      navigate(`/pcrs/${assessmentID}`);
      console.log("redirecting")
    }
  }, [hasSuccessfullyBuilt]);

  useEffect(() => {
      if (pendingRequest) {
        return;
      }

      let timeout_duration = requestCount.current === 0 ? 1 : 5000;
      statusFunctionTimeout = setTimeout(updatePCRSBuildStatus, timeout_duration);
    }, [pendingRequest]
  );

  if(buildingFailed) {
    return <Loading message={"Une erreur s'est produite.  Le rapport PCRS n'a pas pu être initialisé"} error={true}/>
  }

  return <Loading message={"Construction du rapport PCRS en cours..."}/>
}
